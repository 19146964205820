export default {
    Organization: 'Organização',
    Parks: 'Parques',
    Reports: 'Relatórios',
    Users: 'Utilizadores',
    Logout: 'Terminar Sessão',
    OrganizationData: 'Informação geral',
    Search: 'Pesquisar',
    ParkList: 'Lista de Parques',
    CreatePark: 'Criar Parque',
    Name: 'Nome',
    Email: 'E-mail',
    Country: 'País',
    Role: 'Cargo',
    District: 'Distrito',
    County: 'Concelho',
    Parish: 'Freguesia',
    Managers: 'Gerentes',
    Lotation: 'Lotação',
    OcupationTax: 'Taxa de Ocupação (%)',
    Filters: 'Filtros',
    DeleteFiltes: 'Apagar Filtros',
    RowsPerPage: 'Linhas por página',
    ParkProfile: 'Perfil do Parque',
    ParkStructure: 'Estrutura do Parque',
    AddPark: 'Adicionar Parque',
    Map: 'Mapa',
    Transports: 'Veículos',
    TransportsList: 'Lista de Veículos',
    CreateTransport: 'Criar Veículo',
    Departure: 'Partida',
    Arrival: 'Chegada',
    Time: 'Tempo',
    Distance: 'Distância',
    Roadway: 'Autocarro',
    Railway: 'Comboio',
    Subway: 'Metro',
    AddTransport: 'Adicionar Veículo',
    TransportProfile: 'Perfil do Veículo',
    Type: 'Tipo',
    TransportRoutes: 'Rotas que efetua',
    Routes: 'Rotas',
    CreateRoute: 'Criar Rota',
    RouteProfile: 'Perfil da Rota',
    TotalTime: 'Tempo Total',
    EmailAlreadyExists: 'Este email já existe.',
    ManagerTooltip: 'Gerente | Gerentes',
    ParkTooltip: 'Parque | Parques',
    Continue: 'Continuar',
    CreateTrip: 'Criar Viagem',
    CreateCalendar: 'Criar Calendário',
    Capacity: 'Capacidade',
    CreateStopTimes: 'Criar Horários',

    //estrutura do parque
    Floors: 'Andar|Andares',
    Sections: 'Secção | Secções',
    FloorsQuantity: 'Quantidade de Andares',
    NumberFloors: 'Nº de Andares',
    InitialFloor: 'Nº do andar inicial',
    SpotsTotal: 'Número total de lugares',
    AddFloor: 'Adicionar Andar',
    Number: 'Nº',
    Floor: 'Andar',
    Spots: 'Lugar | Lugares',
    SectionsNumber: 'Nº de Secções',
    AddSection: 'Adicionar Secção',
    FloorsNumbers: 'Número total de andares:',
    SectionsNumbers: 'Número total de secções:',
    Limit: 'Número de lugares da secção é maior do que o número de lugares do andar',
    Section: 'Secção',
    Identifier: 'Identificação',
    AddUser: 'Adicionar Utilizador',
    UserProfile: 'Perfil do Utilizador',
    SortBy: 'Ordenar por',
    General: 'Geral',
    Individual: 'Individual',
    SpotTypes: 'Tipo de lugares',
    Quantity: 'Quantidade',
    NormalSpot: 'Lugares normais',
    PregnantSpot: 'Lugares grávidas',
    DisabledSpot: 'Lugares deficientes',
    ParkContent: 'Conteúdo do parque',
    Monitoring: 'Monitorização',
    SpotNumber: 'Número do lugar',
    Sensor: 'Sensor',
    Actions: 'Ações',
    spotQuantityError: 'A quantidade de lugares ultrapassa o número de lugares do andar',
    ParkType: 'Tipo de parque',
    ActivePark: 'Parque ativo',

    //estrutura das rotas
    Agency: 'Agência',
    Description: 'Descrição',
    Route: 'Rota',
    Trips: 'Viagens',
    Trip: 'Viagem',
    Calendar: 'Calendário',
    ShortName: 'Nome curto',
    LongName: 'Nome longo',
    Color: 'Cor',
    TextColor: 'Cor do texto',
    TrajecTorder: 'Ordem de trajeto',
    TripsList: 'Lista de viagens',
    SchedulesList: 'Lista de horários',
    BikesAllowed: 'Bicicletas permitidas',
    WheelchairAcessible: 'Acessível a cadeiras de rodas',
    Headsign: 'Cabeçalho',
    PickupType: 'Tipo de embarque',
    DropOffType: 'Tipo de deembarque',
    CalendarException: 'Exceção de calendário',
    Monday: 'Segunda-feira | Segundas-feiras',
    Tuesday: 'Terça-feira | Terças-feiras',
    Wednesday: 'Quarta-feira | Quartas-feiras',
    Thursday: 'Quinta-feira | Quintas-feiras',
    Friday: 'Sexta-feira | Sextas-feiras',
    Saturday: 'Sábado | Sábados',
    Sunday: 'Domingo | Domingos',
    StartDate: 'Data de início',
    EndDate: 'Data de fim',
    ExceptionType: 'Tipo de exceção',
    Date: 'Data',
    AddRoute: 'Adicionar Rota',
    RouteProfile: 'Perfil da Rota',
    NoInfoBikes: 'Sem informação para esta viagem',
    BikesAllowedInfo: 'O veículo usado nesta viagem em particular pode acomodar pelo menos uma bicicleta',
    BikesNotAllowedInfo: 'Não são permitidas bicicletas nesta viagem',
    NoInfoWheelchair: 'Sem informação para esta viagem',
    WheelchairAllowedInfo:
        'O veículo usado nesta viagem em particular pode acomodar pelo menos um passageiro em cadeira de rodas',
    WheelchairNotAllowedInfo: 'Nenhum passageiro em cadeira de rodas pode ser acomodado nesta viagem',

    //tipos de rotas
    Tram: 'Elétrico',
    Subway: 'Metro',
    Rail: 'Comboio',
    Bus: 'Autocarro',
    Ferry: 'Ferry',
    CableTram: 'Elétrico de cabo',
    AerialLift: 'Elevador aéreo',
    Funicular: 'Funicular',
    Trolleybus: 'Autocarro elétrico',
    Monorail: 'Monocarril',

    //tipos de disponibilidade no calendário

    calendarAvailable: 'Disponível todos(as) os(as)',
    calendarNotAvailable: 'Não disponível todos(as) os(as)',

    calendarDatesAvailable: 'Serviço disponível nesta data',
    calendarDatesNotAvailable: 'Serviço indisponível nesta data',

    //tipos de pickup e dropoff
    ContinuosPickup: 'Pickup de parada contínuo',
    NoContinuosPickup: 'Sem pickup de parada contínua',
    PhoneAgencyPickup: 'Deve telefonar para a agência para fazer o pickup',
    PhoneDriverPickup: 'Deve coordenar com um motorista para fazer o pickup',
    RegularPickup: 'Pickup regular',
    NoPickupAvailable: 'Não há pickup disponível',

    ContinuosDropOff: 'Desembarque de parada contínuo',
    NoContinuosDropOff: 'Sem desembarque de parada contínua',
    PhoneAgencyDropOff: 'Deve telefonar para a agência para fazer o desembarque',
    PhoneDriverDropOff: 'Deve coordenar com um motorista para fazer o desembarque',
    RegularDropOff: 'Desembarque regular',
    NoDropOffAvailable: 'Não há desembarque disponível',

    //trip form
    Direction: 'Direção',
    AddTrip: 'Adicionar Viagem',
    TripProfile: 'Perfil da Viagem',
    DirectionOutbound: 'Viaja numa direção (por exemplo, viagem de ida)',
    DirectionInbound: 'Viaja na direção oposta (por exemplo, viagem de volta)',
    ApproximateTime: 'Tempo aproximado',
    ExactTime: 'Tempo exato',

    //stop times form
    Sequence: 'Sequência',
    ShapeDistance: 'Distância até à próxima paragem',
    Timepoint: 'Tempo de paragem',
    ArrivalTime: 'Hora de chegada',
    DepartureTime: 'Hora de partida',
    AddStopTime: 'Adicionar horário',
    StopTimeProfile: 'Perfil do horário',
    Stop: 'Paragem',

    //calendar form
    CalendarProfile: 'Perfil do Calendário',
    AddCalendar: 'Adicionar Calendário',
    AlwaysAvailable: 'Sempre disponível',
    NotAlwaysAvailable: 'Não está sempre disponível',

    //calendar dates form
    CreateCalendarDate: 'Criar exceção ao calendário',

    //alerts
    Alerts: {
        Success: 'Sucesso!',
        UpdateSuccessEnt: 'Informação atualizada com sucesso!',
        Error: 'Erro!',
        ErrorMessage: 'Ocorreu um erro. Tente novamente mais tarde.',
        DeleteSuccess: 'Parque eliminado com sucesso.',
        UpdateSuccess: 'Parque atualizado com sucesso.',
        AddSuccess: 'Parque adicionado com sucesso.',
        Sure: 'Tem a certeza?',
        SureDelete: 'Tem a certeza que pretende eliminar o parque',
        SureDeteteParks: 'Tem a certeza que pretende eliminar os parques',
        DeleteUser: 'Tem a certeza que pretende eliminar o utilizador',
        SureDeteteUsers: 'Tem a certeza que pretende eliminar os utilizadores',
        Irreversible: 'Esta ação é irreversível.',
        DeleteSuccessUser: 'Utilizador eliminado com sucesso.',
        AddUserSuccess: 'Utilizador adicionado com sucesso.',
        UpdateUserSuccess: 'Utilizador atualizado com sucesso.',
        DeleteUserSuccess: 'Utilizador eliminado com sucesso.',
        AddTransportSuccess: 'Veículo adicionado com sucesso.',
        UpdateTransportSuccess: 'Veículo atualizado com sucesso.',
        DeleteTransportSuccess: 'Veículo eliminado com sucesso.',
        DeleteTransport: 'Tem a certeza que pretende eliminar o veículo',
        SureDeleteTransports: 'Tem a certeza que pretende eliminar os veículos',
        SureDeleteFloor: 'Tem a certeza que pretende eliminar o andar',
        SureDeleteSpot: 'Tem a certeza que pretende eliminar o lugar',
        SureDeleteSection: 'Tem a certeza que pretende eliminar a secção',
        SureDeleteGeneralLot: 'Tem a certeza que pretende eliminar os',
        AddRouteSueccess: 'Rota adicionada com sucesso.',
        UpdateRouteSueccess: 'Rota atualizada com sucesso.',
        DeleteRouteSueccess: 'Rota eliminada com sucesso.',
        SureDeleteRoute: 'Tem a certeza que pretende eliminar a rota',
        AddTripSueccess: 'Viagem adicionada com sucesso.',
        UpdateTripSueccess: 'Viagem atualizada com sucesso.',
        DeleteTripSueccess: 'Viagem eliminada com sucesso.',
        SureDeleteTrip: 'Tem a certeza que pretende eliminar a viagem',
        DeleteRouteSueccess: 'Viagem eliminada com sucesso.',
        AddStopTimeSuccess: 'Horário adicionado com sucesso.',
        UpdateStopTimeSuccess: 'Horário atualizado com sucesso.',
        DeleteStopTimeSuccess: 'Horário eliminado com sucesso.',
        SureDeleteStopTime: 'Tem a certeza que pretende eliminar o horário',
        AddCalendarSuccess: 'Calendário adicionado com sucesso.',
        UpdateCalendarSuccess: 'Calendário atualizado com sucesso.',
        DeleteCalendarSuccess: 'Calendário eliminado com sucesso.',
        SureDeleteCalendar: 'Tem a certeza que pretende eliminar o calendário',
    },
    //buttons
    Buttons: {
        Save: 'Guardar',
        Conclude: 'Concluir',
        Cancel: 'Cancelar',
        Edit: 'Editar',
        Delete: 'Apagar',
        Filter: 'Filtrar',
        Add: 'Adicionar',
    },

    Form: {
        Name: 'Nome',
        Email: 'E-mail',
        Nif: 'NIF',
        Phone: 'Telemóvel',
        Schedule: 'Horário',
        Cost: 'Custo',
        ParkManagers: 'Gestores do Parque',
        Sensors: 'Sensores',
        Address: 'Morada',
        PostalCode: 'Código Postal',
        LatLng: 'Latitude, Longitude',
        Gender: 'Género',
        TransportManagers: 'Gestores do Veículo',
    },

    Language: {
        English: 'Inglês',
        Portuguese: 'Português',
    },
    Login: {
        Login: 'Login',
        Welcome: 'Bem-vindo ao ioCity',
        Password: 'Palavra-passe',
        Forgot: 'Esqueceu-se da palavra-passe?',
    },
};
