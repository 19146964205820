import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: 'login-demo',
    },

    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/login-demo',
        name: 'LoginDemo',
        component: () => import('@/views/LoginDemo.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/reports',
        name: 'Reports',
        component: () => import('@/views/Reports.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/organization',
        name: 'Organization',
        component: () => import('@/views/organization/OrganizationForm.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/park-list',
        name: 'ParkList',
        component: () => import('@/views/parks/Park.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'ParkList',
                component: () => import('@/views/parks/park-list/ParkList.vue'),
            },
            {
                path: 'park-profile/:id',
                name: 'ParkProfile',
                component: () => import('@/views/parks/park-profile/ParkForm.vue'),
            },
        ],
    },
    {
        path: '/user-list',
        name: 'UserList',
        component: () => import('@/views/user/User.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'UserList',
                component: () => import('@/views/user/user-list/UserList.vue'),
            },
            {
                path: 'user-profile/:id',
                name: 'UserProfile',
                component: () => import('@/views/user/user-profile/UserForm.vue'),
            },
        ],
    },

    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/Login.vue'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/transports-list',
        name: 'TransportsList',
        component: () => import('@/views/transports/Transports.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'TransportsList',
                component: () => import('@/views/transports/transports-list/TransportsList.vue'),
            },
            {
                path: 'transport-profile/:id',
                name: 'TransportProfile',
                component: () => import('@/views/transports/transports-profile/TransportForm.vue'),
            },
        ],
    },
    {
        path: '/routes-list',
        name: 'RoutesList',
        component: () => import('@/views/route/Route.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'RoutesList',
                component: () => import('@/views/route/MainTabs.vue'),
            },
            {
                path: 'route-profile/:id',
                name: 'RouteProfile',
                component: () => import('@/views/route/routes-profile/RoutesForm.vue'),
            },
            {
                path: 'trip-profile/:id',
                name: 'TripProfile',
                component: () => import('@/views/route/trips-profile/TripsForm.vue'),
            },
            {
                path: 'stop-times-profile/:id',
                name: 'StopTimesProfile',
                component: () => import('@/views/route/stop-times-profile/StopTimesForm.vue'),
            },
            {
                path: 'calendar-profile/:id',
                name: 'CalendarProfile',
                component: () => import('@/views/route/calendar/calendar-profile/CalendarForm.vue'),
            },
            {
                path: 'calendar-dates-profile/:id',
                name: 'CalendarDatesProfile',
                component: () => import('@/views/route/calendar/calendar-dates-profile/CalendarDatesForm.vue'),
            },
        ],
    },
    {
        path: '*',
        redirect: 'error-404',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

export default router;
