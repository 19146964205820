import { i18n } from '@/plugins/i18n';
import {
    mdiAccountOutline,
    mdiHomeOutline,
    mdiNoteTextOutline,
    mdiParking,
    mdiBus,
    mdiBusStopUncovered,
} from '@mdi/js';

export default [
    {
        title: 'Organization',
        icon: mdiHomeOutline,
        to: 'Organization',
    },
    {
        title: 'Transports',
        icon: mdiBus,
        to: 'TransportsList',
    },
    {
        title: 'Routes',
        icon: mdiBusStopUncovered,
        to: 'RoutesList',
    },

    {
        title: 'Parks',
        icon: mdiParking,
        to: 'ParkList',
    },
    {
        title: 'Reports',
        icon: mdiNoteTextOutline,
        to: 'Reports',
    },
    {
        title: 'Users',
        icon: mdiAccountOutline,
        to: 'UserList',
    },
];
